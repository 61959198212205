.notificationContainer {
  background-color: white;
  border-radius: 4px;
  width: 100%;
}

.title {
  font-family: "Wigrum", sans-serif;
  font-size: 21px;
  line-height: 28px;
  color: #000;
  letter-spacing: -0.2px;
}

.inbox {
  font-family: "Nunito Sans", sans-serif;
  font-size: 16px;
  color: #000;
  text-decoration: none;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  padding-top: 8px;
  padding-bottom: 8px;
  padding-left: 16px;
  padding-right: 16px;
  border-bottom-style: solid;
  border-bottom-color: #ddd;
  border-bottom-width: 1px;


}

.divider {
  background-color: #ddd;
  height: 1px;
  width: 100%;
}

.topShadow {
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  opacity: 0.5;
  height: 5px;
}

.bottomShadow {
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  opacity: 0.5;
  height: 5px;
}

.scrolled {
  box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.5);
}
