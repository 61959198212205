.notificationItemContainer{
  font-family: "Nunito Sans";
  margin-right: 8px;
  margin-left: 8px;
}

.notificationTitle {
  color: #0078ab;
  font-size: 16px;
}

.information {
  display: flex;
  flex-direction: column;
  margin-left: 8px;
}

.dateTime {
  font-size: 14px;
}

.notificationButton {
  border-radius: 50px;
  color: #fff;
  font-size: 14px;
  min-width: 100px;
  padding-top: 2px;
  padding-bottom: 2px;
  text-align: center;
}

.iconEye {
  margin-left: 4px;
  margin-right: 4px;
}

.divider {
  background-color: #ddd;
  height: 1px;
  margin-right: 8px;
  margin-left: 8px;
}
